<template>
  <div class="base-page">
    <!-- 个人信息 -->
    <div class="base-main">
      <!-- 贷款相关 -->
      <div class="main-first">
        <div class="main-line introCity">
         <span class="main-line-name">贷款金额</span>
          <!-- :value='userInfo.nickName'value="赵达" -->
          <input
            class="main-input"
            type='number' 
            pattern="[0-9]*" 
            placeholder="请输入贷款金额"
            v-model="loanVal"
            @change="loanChange($event)"
          />
        </div>
        <div class="main-t">
          <van-field
            v-model.trim="periodValue"
            is-link
            readonly
            name="picker"
            label="贷款期限"
            placeholder="请选择贷款期限"
            @click="showPerode = true"
          />
          <van-popup v-model:show="showPerode" position="bottom">
            <van-picker
              value-key="key"
              :columns="loanPeriod"
              @confirm="loanPeriodChange"
              @cancel="showPerode = false"
            />
          </van-popup>
        </div>
        <div class="main-t">
          <van-field
            v-model.trim="loanUseVal"
            is-link
            readonly
            name="picker"
            label="贷款用途"
            placeholder="请选择贷款用途"
            @click="showloanUse = true"
          />
          <van-popup v-model:show="showloanUse" position="bottom">
            <van-picker
              value-key="key"
              :columns="loanUseList"
              @confirm="loanUserChange"
              @cancel="showloanUse = false"
            />
          </van-popup>
        </div>
      </div>
      <!-- 地址 -->
      <div class="oneBox area">
        <div class="main-t">
          <van-field
            v-model.trim="liveCity"
            is-link
            readonly
            name="area"
            label="现居城市"
            placeholder="请选择居住城市"
            @click="showArea = true"
          />
          <van-popup v-model:show="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              :columns-num="3"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
        </div>

        <div class="main-line introCity">
          <span class="main-line-name">详细地址</span>
          <!-- :value='userInfo.nickName'value="赵达" -->
          <input
            class="main-input"
            placeholder="请输入现居住地址"
            :value="cityAddress"
            @change="cityChange($event)"
          />
        </div>
      </div>
      <div class="oneBox">
        <!-- 婚姻状态 -->
        <div class="main-t">
          <van-field
            v-model.trim="marriageValue"
            is-link
            readonly
            name="picker"
            label="婚姻状态"
            placeholder="请选择婚姻状态"
            @click="showMarriage = true"
          />
          <van-popup v-model:show="showMarriage" position="bottom">
            <van-picker
              value-key="key"
              :columns="marriageList"
              @confirm="modalMarriage"
              @cancel="showMarriage = false"
            />
          </van-popup>
        </div>
        <!-- 配偶 -->
        <!-- <div class="marriage" v-if="MarriageStatu == false">
          <div class="main-line">
            <span class="main-line-name">配偶姓名</span>
            <input
              class="main-input"
              placeholder="请输入配偶姓名"
              :value="spouseName"
              @change="spouseNameChange($event)"
            />
          </div>
          <div class="main-line">
            <span class="main-line-name">配偶身份证号</span>
            <input
              class="main-input"
              placeholder="请输入配偶身份证号"
              :value="spouseId"
              @change="spouseIdChange($event)"
            />
          </div>
          <div class="main-line">
            <span class="main-line-name">配偶工作地址</span>
            <input
              class="main-input"
              placeholder="请输入配偶工作地址"
              :value="spouseAddress"
              @change="spouseAddressChange($event)"
            />
          </div>
          <div class="main-line">
            <span class="main-line-name">配偶工作单位</span>
            <input
              class="main-input"
              placeholder="请输入配偶工作单位"
              :value="spouseWorkName"
              @change="spouseWorkChange($event)"
            />
          </div>
          <div class="main-line">
            <span class="main-line-name">配偶手机号码</span>
            <input
              class="main-input"
              placeholder="请输入配偶手机号码"
              type="tel"
              maxlength="11"
              :value="spouseMobile"
              @change="spouseMobileChange($event)"
            />
          </div>
        </div> -->
        <!-- 学历 -->
        <div class="main-t">
          <van-field
            v-model.trim="infoValue"
            is-link
            readonly
            name="picker"
            label="学历"
            placeholder="请选择学历"
            @click="showPicker = true"
          />
          <van-popup v-model:show="showPicker" position="bottom">
            <van-picker
              value-key="key"
              :columns="eduPickerData"
              @confirm="modalCheck"
              @cancel="showPicker = false"
            />
          </van-popup>
        </div>
         <!-- QQ/微信 -->
        <!-- <div class="main-line main-t">
          <van-field
            class="money"
            v-model.trim="qqwechat"
            label="QQ/微信"
            placeholder="请输入QQ/微信号"
            input-align="right"
          />
        </div> -->
        <div class="main-t">
          <van-field
            v-model.trim="MonthlyValue"
            is-link
            readonly
            name="picker"
            label="月收入"
            placeholder="请选择月收入"
            @click="showMonthly = true"
          />
          <van-popup v-model:show="showMonthly" position="bottom">
            <van-picker
              value-key="key"
              :columns="monthIncomeList"
              @confirm="modalColumns"
              @cancel="showMonthly = false"
            />
          </van-popup>
        </div>
       
        <!-- 月收入 -->
      </div>
      <div class="work">
        <div class="main-t city-work">
          <van-field
            v-model.trim="kindValue"
            is-link
            readonly
            name="workKind"
            label="工作行业"
            placeholder="请选择工作行业"
            @click="showWorkKind = true"
          />
          <van-popup v-model:show="showWorkKind" position="bottom">
            <van-picker
              value-key="key"
              :columns="workkindList"
              @confirm="onWorkkinds"
              @cancel="showWorkKind = false"
            />
          </van-popup>
        </div>
        <div class="main-t city-work">
          <van-field
            v-model.trim="cityVal"
            is-link
            readonly
            name="area"
            label="工作城市"
            placeholder="请选择工作城市"
            @click="showAreacity = true"
          />
          <van-popup v-model:show="showAreacity" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onCity"
              @cancel="showAreacity = false"
            />
          </van-popup>
        </div>
        <div class="main-line main-y">
          <span class="main-line-name">公司全称</span>
          <input
            class="main-input"
            placeholder="请输入公司全称"
            :value="companyVal"
            style="text-align: right"
            @change="companyChange($event)"
          />
        </div>
        <div class="main-line">
          <span class="main-line-name">工作地址</span>
          <input
            class="main-input"
            placeholder="请输入详细工作地址"
            :value="workAddress"
            style="text-align: right"
            @change="workChange($event)"
          />
        </div>
      </div>
      <div class="infrom-main inform" @click="relationSing('one')">
        <div class="time-r">常用联系人1</div>
        <div class="time-l">
          <!-- :value="newContactF" -->
          <input class="loan-input" readonly="readonly" />
          <span class="infrom-icon">+通讯录</span>
        </div>
      </div>
      <!--联系人1姓名电话-->
      <template v-if="contactValue">
        <div class="main-line">
          <van-field
            v-model.trim="linkerName"
            label="联系人姓名"
            placeholder="请输入"
            input-align="right"
          />
        </div>
        <div class="main-line">
          <van-field
            v-model.trim="linkerMobile"
            label="联系人电话"
            input-align="right"
            disabled
          />
        </div>
      </template>

      <div class="main-t">
        <van-field
          v-model="relationVal"
          is-link
          readonly
          input-align="right"
          name="picker"
          label="与您的关系"
          placeholder="请选择与您的关系"
          @click="showRelation = true"
        />
        <van-popup v-model:show="showRelation" position="bottom">
          <van-picker
            value-key="key"
            :columns="relationList"
            @confirm="modalRelation"
            @cancel="showRelation = false"
          />
        </van-popup>
      </div>
      <div class="infrom-main" @click="relationSing('two')">
        <div class="time-r">常用联系人2</div>
        <div class="time-l">
          <input class="loan-input" readonly="readonly" />
          <span class="infrom-icon">+通讯录</span>
        </div>
      </div>
      <!--联系人2姓名电话-->
      <template v-if="contactTValue">
        <div class="main-line">
          <van-field
            v-model.trim="linkerNames"
            label="联系人姓名"
            placeholder="请输入"
            input-align="right"
          />
        </div>
        <div class="main-line">
          <van-field
            v-model.trim="linkerMobiles"
            label="联系人电话"
            input-align="right"
            disabled
          />
        </div>
      </template>

      <div class="main-t">
        <van-field
          v-model="relationValT"
          is-link
          readonly
          input-align="right"
          name="picker"
          label="与您的关系"
          placeholder="请选择与您的关系"
          @click="showRelationT = true"
        />
        <van-popup v-model:show="showRelationT" position="bottom">
          <van-picker
            value-key="key"
            :columns="relationList"
            @confirm="modalRelationT"
            @cancel="showRelationT = false"
          />
        </van-popup>
      </div>
    </div>
    <div class="base-footer-text">
       <!--<van-checkbox
        class="base-footer-text"
        icon-size="20px"
        checked-color="#8486F8"
        v-model="checked"
        >我不是学生
      </van-checkbox>-->
    </div> 
    <div class='base-btns' @click="subitm">
      确认并提交
    </div>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs, reactive } from "vue";
import API from "../../server/api";
import { areaList } from "@vant/area-data";
import Modal from "@/components/Modal/Modal.vue";
import { Toast } from "vant";

export default {
  components: { Modal },
  setup() {
    const state = reactive({
      liveCity: "", // 现居城市
      cityAddress: "", // 详细地址
      liveCode: "", // 现居住code码
      workCode: "",
      kindVal: "", // 工作行业
      kindValue: "", // 工作行业
      workkindList: [], // 行业列表
      cityVal: "", // 工作城市
      showWorkKind: false, // 工作行业弹框
      showArea: false,
      qqwechat: "",
      showback: false,
      infoValue: "",
      infoVal: "",
      MonthlyVal: "",
      marriageVal: "",
      eduPickerData: [],
      showPicker: false,
      MonthlyValue: "", // 收入
      monthIncomeList: [], // 月收入
      showMonthly: false,
      showMarriage: false,
      showPerode:false,
      marriageValue: "", // 婚姻
      loanPeriod: [],
      periodValue:'',
      loanUseList:[],
      loanUseVal:"",
      marriageList: [],
      relationVal: "",
      relationList: [],
      showRelation: false,
      relationValT: "",
      showRelationT: false,
      companyVal: "", // 公司名称
      workAddress: "",
      contactValue: "",
      contactTValue: "",
      relationV: "",
      relationVT: "",
      mobileList: [], // 联系人数组
      MarriageStatu: false, // 已婚弹框
      spouseName: "",
      spouseId: "",
      spouseAddress: "",
      spouseWorkName: "",
      spouseMobile: "",
      linkerName: "", // 联系人1
      linkerMobile: "", // 联系人1手机号
      linkerNames: "", // 联系人2
      linkerMobiles: "", // 联系人2手机号
      showloanUse: false,
      showAreacity: false,
      kindList: [], // 工作行业列表
      creditNo: "", // 授信单号
      clientCity: "",
      clientProvince: "",
      clientRegion: "",
      lat: "",
      lng: "",
      phoneMail:"",//通讯录
       // 通讯录相关
      relationOne: {
        linkerName: '',
        linkerMobile: '',
        relation: ''
      },
      relationTwo: {
        linkerName: '',
        linkerMobile: '',
        relation: ''
      },
      newContactF:"",
      newContactT:'',
      loanVal:""//借款金额
    });
    const baseInfo = async () => {
      const res = await API.baseInfo({});
      console.log(res.result, "基础信息");
      const {educationCode,loanPeriod,marriage,selfMonthIncome,workIndustry,relation,loanUse}=res.result
      state.eduPickerData = educationCode; // 学历
      // console.log(state.eduPickerData, "基础信息");
      state.loanPeriod = loanPeriod;//借款期限
      state.marriageList = marriage; // 婚姻状态
      state.monthIncomeList = selfMonthIncome; // 月收入
      state.workkindList = workIndustry; // 工作行业
      state.relationList = relation;//关系
      state.loanUseList = loanUse;//关系
    };
    // 身份证输入校验
    const checkIDCard = (idcode) => {
      // 加权因子
      // eslint-disable-next-line camelcase
      var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 校验码
      // eslint-disable-next-line camelcase
      var check_code = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

      var code = idcode + "";
      var last = idcode[17]; // 最后一位

      var seventeen = code.substring(0, 17);

      // ISO 7064:1983.MOD 11-2
      // 判断最后一位校验码是否正确
      var arr = seventeen.split("");
      var len = arr.length;
      var num = 0;
      for (var i = 0; i < len; i++) {
        num = num + arr[i] * weight_factor[i];
      }

      // 获取余数
      var resisue = num % 11;
      var last_no = check_code[resisue];

      var idcard_patter =
        /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X]|[x])$/;

      // 判断格式是否正确
      var format = idcard_patter.test(idcode);

      // console.log(format,last.toUpperCase(),last_no,88888888)

      const lastUpp = last.toUpperCase();

      // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
      return !!(lastUpp === last_no && format);
    };
    const onConfirm = (areaValues) => {
      state.showArea = false;
      state.liveCity = areaValues
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("-");
      state.liveCode = areaValues
        .filter((item) => !!item)
        .map((item) => item.code)
        .join(",");
      console.log(state.liveCode);
    };
    const onCity = (areaValues) => {
      state.showAreacity = false;
      state.cityVal = areaValues
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("-");
      state.workCode = areaValues
        .filter((item) => !!item)
        .map((item) => item.code)
        .join(",");
    };
    const onWorkkinds = (e) => {
      state.showWorkKind = false;
      state.kindVal = e.value;
      state.kindValue = e.key;
    };
    const modalCheck = (e) => {
      //    console.log(e.key,997);
      state.infoValue = e.key;
      state.infoVal = e.value;
      state.showPicker = false;
    };
    const modalColumns = (e) => {
      state.MonthlyValue = e.key;
      state.MonthlyVal = e.value;
      state.showMonthly = false;
    };
    const modalMarriage = (e) => {
      // if (e.key == "MARRIED") {
      //   //弹框
      //   state.MarriageStatu = true;
      // } else {
      //   state.MarriageStatu = false;
      // }
      state.marriageValue = e.key;
      state.marriageVal = e.value;
      state.showMarriage = false;
    };
    const loanChange=(e)=>{
      console.log(e,'借款金额')
      if(e.target.value<3000){
         Toast('请输入正确的借款额度')
          return false
      }
      if (e.target.value % 1000 !== 0) {
            Toast('请输入1000的整数倍');
            return false;
      }
      state.loanVal=e.target.value;
    }
    //借款期限
    const loanPeriodChange=(e)=>{
      console.log(e,'yongtu')
      state.periodValue=e
      state.showPerode=false
    }
    //借款用途
    const loanUserChange=(e)=>{
      console.log(e,'yongtu')
      state.loanUseVal=e
      state.showloanUse=false
    }
    const spouseNameChange = (e) => {
      state.spouseName = e.target.value;
    };
    const spouseIdChange = (e) => {
      if (
        !checkIDCard(e.target.value) &&
        e.target.value.length >= 15 &&
        e.target.value.length > 18
      ) {
        Toast("请输入正确的身份证号");
        return false;
      }
      state.spouseId = e.target.value;
    };
    const spouseAddressChange = (e) => {
      state.spouseAddress = e.target.value;
    };
    const spouseWorkChange = (e) => {
      state.spouseWorkName = e.target.value;
    };
    const spouseMobileChange = (e) => {
      // console.log(e.target.value.length,9993)
      state.spouseMobile = e.target.value;
    };
    const modalRelation = (e) => {
      // console.log(e,999);
      state.relationVal = e.key;
      state.relationV = e.value;
      state.showRelation = false;
    };
    const modalRelationT = (e) => {
      state.relationValT = e.key;
      state.relationVT = e.value;
      state.showRelationT = false;
    };
    const wechatChange = (e) => {
      state.qqwechat = e.target.value;
    };
    const cityChange = (e) => {
      state.cityAddress = e.target.value;
    };
    const companyChange = (e) => {
      state.companyVal = e.target.value;
    };
    const workChange = (e) => {
      state.workAddress = e.target.value;
    };
    // 联系人
    const relationSing = (type) => {
      // 调用app方法
      APP.PickContact(function (res) {
        if(res){
          const { linkerName, linkerMobile } = JSON.parse(res)
          console.log(res,linkerName,typeof(res), '通讯录内容')
          switch(type){
            case 'one':
              state.relationOne.linkerName = linkerName
              state.relationOne.linkerMobile = linkerMobile
              state.contactValue=linkerName + linkerMobile
              state.linkerName=linkerName
              state.linkerMobile=linkerMobile
              console.log(JSON.stringify(state.relationOne), '新内容')
              break
            case 'two':
              state.relationTwo.linkerName = linkerName
              state.relationTwo.linkerMobile = linkerMobile
              state.contactTValue=linkerName + linkerMobile
              state.linkerNames=linkerName
              state.linkerMobiles=linkerMobile
              console.log(JSON.stringify(state.relationTwo), '新内容2')
              break 
          }
        }
      });

      APP.Contacts(function(res){
        console.log(res,'全部联系人')
        state.phoneMail=res
      })
    };
    const subitm = async () => {
      if (state.liveCity === "") {
        Toast("请选择现居住城市");
        return false;
      }
      if (state.cityAddress === "") {
        Toast("请填写详细地址");
        return false;
      }
      if (state.marriageVal === "") {
        Toast("请选择婚姻状况");
        return false;
      }
      if (state.infoVal === "") {
        Toast("请选择学历");
        return false;
      }

      if (state.MonthlyVal === "") {
        Toast("请选择月收入");
        return false;
      }
      // if (state.qqwechat === "") {
      //   Toast("请填写QQ/微信号");
      //   return false;
      // }
      if (state.kindVal === "") {
        Toast("请选择工作行业");
        return false;
      }
      if (state.cityVal === "") {
        Toast("请选择工作城市");
        return false;
      }
      if (state.companyVal === "") {
        Toast("请填写公司名称");
        return false;
      }
      if (state.workAddress === "") {
        Toast("请填写工作地址");
        return false;
      }
      if (state.contactValue === "") {
          Toast("请选择联系人1");
          return false;
      }
      
      if (state.relationVal === "") {
        Toast("请选择与联系人1的关系");
        return false;
      }
       if (state.linkerName === "") {
        Toast("请输入联系人1的姓名");
        return false;
      }
        if (state.linkerNames === "") {
        Toast("请输入联系人2的姓名");
        return false;
      }
      if (state.contactTValue === "") {
        Toast("请选择联系人2");
        return false;
      }
      if (state.relationValT === "") {
        Toast("请选择与联系人2的关系");
        return false;
      }
      if (state.contactValue === state.contactTValue) {
        Toast("联系人1与联系人2不能相同");
        return false;
      }
      // if (!state.checked) {
      //   Toast.fail("请确认您是否为学生!");
      //   return false;
      // }
      const res = await API.baseSubmit({
        companyAddress: state.cityVal,
        companyAddressDetail: state.workAddress,
        companyName: state.companyVal,
        educationCode: state.infoVal,
        firstName: state.linkerName,
        firstPhone: state.linkerMobile,
        firstRelation: state.relationV,
        secondName: state.linkerNames,
        secondPhone: state.linkerMobiles,
        secondRelation: state.relationVT,
        liveAddress: state.liveCity,
        liveDetailAddress: state.cityAddress,
        marriage: state.marriageVal,
        // qqWechar: state.qqwechat,
        selfMonthIncome: state.MonthlyVal,
        workIndustry: state.kindVal,
        creditNo: state.creditNo,
        clientCity: state.clientCity,
        clientProvince: state.clientProvince,
        clientRegion: state.clientRegion,
        lat: state.lat,
        
        lng: state.lng,

        link:state.phoneMail, //全部通讯录
        loanAmount:state.loanVal,
        loanPeriod:state.periodValue,
        loanUse:state.loanUseVal
      });
      console.log(res, 3422);
      // 成功跳转到信息提交页
      if (res) {
        // console.log(res, "信息提交");
        // 成功-跳转到上传图片
        APP.Back(1)
      }
    };
    // 获取GPS信息
    const getLocationInfo = async () => {
      await APP.GetLocation((res)=>{
        const {lat,lng, address}=JSON.parse(res)
        const reg= /.+?(省|市|自治区|自治州|县|区)/g
        const newAdress=address.match(reg)
        console.log(newAdress,'地理位置')
        state.clientCity = newAdress[2];
        state.clientProvince =newAdress[0]; //省
        state.clientRegion =newAdress[1];
        state.lat = lat;
        state.lng = lng;
      })
    };
    // 获取基础信息（反显）
    const getBaseInfomation = async () => {
      API.getBaseInfo().then((res) => {
        // console.log(res, '反显内容')
        const {
          liveAddress,
          liveDetailAddress,
          marriageKey,
          marriage,
          educationCodeKey,
          educationCode,
          selfMonthIncomeKey,
          selfMonthIncome,
          qqWechar,
          workIndustryKey,
          workIndustry,
          companyAddress,
          companyName,
          companyAddressDetail,
          firstName,
          firstPhone,
          secondName,
          secondPhone,
          firstRelation,
          firstRelationKey,
          secondRelation,
          secondRelationKey,
          loanAmount,
          loanPeriod,
          loanUse
        } = res;
        state.loanVal=loanAmount;
        state.periodValue=loanPeriod;
        state.loanUseVal=loanUse;
        state.liveCity = liveAddress;
        state.cityAddress = liveDetailAddress;
        state.marriageValue = marriageKey;
        state.marriageVal = marriage;
        state.infoValue = educationCodeKey;
        state.infoVal = educationCode;
        state.MonthlyValue = selfMonthIncomeKey;
        state.MonthlyVal = selfMonthIncome;
        // state.qqwechat = qqWechar;
        state.kindValue = workIndustryKey;
        state.kindVal = workIndustry;
        state.cityVal = companyAddress;
        state.companyVal = companyName;
        state.workAddress = companyAddressDetail;
        state.linkerName = firstName;
        state.linkerMobile = firstPhone;
        state.contactValue = firstName + firstPhone;
        state.linkerNames = secondName;
        state.linkerMobiles = secondPhone;
        state.contactTValue = secondName + secondPhone;
        state.relationV = firstRelation;
        state.relationVal = firstRelationKey;
        state.relationVT = secondRelation;
        state.relationValT = secondRelationKey;
      });
    };
    const GetLocations=()=>{
      APP.NewGetLocations((res)=>{
          console.log(res,typeof(res),'GetLocation98')
          const ress=JSON.parse(res)
          // {longitude,latitude }
          console.log(ress,'GetLocation99')
          if(ress){
            const {longitude,latitude }=ress
            state.lat=latitude
            state.lng=longitude
            
          }else{
            Toast("获取地理位置信息异常，请重新进入此页面或者重装app");
          }
         
      })
    }

    onMounted(() => {
      state.creditNo = localStorage.getItem("creditNo");
      const location=localStorage.getItem('locationInfo')
      if(location!=null){
        console.log(location,'GetLocation67')
        const {lat,lng}=JSON.parse(location)
        state.lat = lat;
        state.lng = lng;
        
      }else{
        console.log('GetLocation68')
        // NewGetLocations()
        // Toast("获取地理位置信息异常，可能导致后面流程无法操作"); 
      }


      baseInfo();
      // getLocationInfo();
      getBaseInfomation();
      // GetLocations()
    });
    return {
      ...toRefs(state),
      areaList,
      wechatChange,
      modalCheck,
      modalColumns,
      modalMarriage,
      modalRelation,
      modalRelationT,
      companyChange,
      workChange,
      relationSing,
      subitm,
      spouseNameChange,
      spouseIdChange,
      spouseAddressChange,
      spouseWorkChange,
      spouseMobileChange,
      onConfirm,
      cityChange,
      onCity,
      onWorkkinds,
      loanPeriodChange,
      loanUserChange,
      loanChange
    };
  },
};
</script>
<style>
.work .van-field__control {
  text-align: right;
}

.base-page .van-cell_label {
  color: #212121;
}

.base-page .van-cell__title.van-field__label {
  color: #212121;
}
</style>
